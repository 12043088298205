<template>
  <v-app>
    <nav-bar />
    <v-main class="main-content">
      <router-view />
    </v-main>
    <footer-bar />
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterBar from './components/FooterBar.vue';

export default {
  components: { NavBar, FooterBar },
};
</script>

<style scoped lang="scss">
.main-content {
  background-color: $primary-20;
}

</style>
