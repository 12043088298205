<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <v-footer class="py-8 footer-colored">
    <v-col cols="12" md="4" align-self="start">
      <div>
        <svg
          class="fill-base-content"
          enable-background="new 0 0 600 149.6"
          version="1.1"
          viewBox="0 0 600 149.6"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="st0"
            d="m251.5 63.6h8.7l-2.7 12.1h0.2c4.4-7.7 11-13.4 20.5-13.4 1.2 0 2.1-0.3 3.3 0l-2.2 9.9c-0.4-0.1-0.9-0.1-1.4-0.1h-1.3c-2.3 0-3.4 0-5.6 0.5-5.1 1.3-9 4.7-12 9.1-2.5 3.6-3.8 8.9-4.7 13.2l-5.3 24.9h-9.3l11.8-56.2z"
          />
          <path
            class="st0"
            d="m307.2 62.3c15.1 0 23.4 8.2 23.4 23.3 0 18.3-11.4 35.6-31.1 35.6-14.8 0-23.7-9.9-23.7-23.5-0.1-18.4 11.7-35.4 31.4-35.4zm-6.8 50.7c13.5 0 20.9-15.7 20.9-26.7 0-8.8-4.7-15.9-14.8-15.9-14.3 0-21.6 15.5-21.6 27.1 0.1 9.3 5.4 15.5 15.5 15.5z"
          />
          <path
            class="st0"
            d="m361.3 88.7-20.5-46.5h11l15.9 37.7 29.5-37.7h12l-37.7 46.5-6.8 31.2h-10.1l6.7-31.2z"
          />
          <path
            class="st0"
            d="m403.2 80.6c1.7-12.5 12.5-18.3 24.4-18.3 10.9 0 20.6 3 20.6 14.4 0 3.2-1 7.2-1.7 10.2l-3.6 15.3c-0.4 2.3-1.5 5.4-1.5 8.1 0 2.6 1.1 3.5 2.7 3.5 0.9 0 2.1-0.4 2.9-0.8l-1.4 6.9c-1.4 0.8-4 1.3-5.7 1.3-5.1 0-7.8-3-7.3-7.9l-0.2-0.2c-3.9 5.2-9.3 8.2-18.8 8.2-9.4 0-17.2-4.4-17.2-15.9 0-16.3 15.9-17.3 28.4-18.5 9.9-0.9 14.2-1.4 14.2-8.9 0-6-6.4-7.4-11.3-7.4-7.1 0-13.9 2.8-15.1 10.1h-9.4zm12.5 32.4c7.3 0 12.6-2.5 15.8-7.4 3-4.2 3.6-9.3 4.9-14.5h-0.2c-7.7 5.6-30.4-0.2-30.6 14 0 5.3 4.9 7.9 10.1 7.9z"
          />
          <path
            class="st0"
            d="m496.5 81.5c0-7-4.6-11-11.9-11-14.3 0-21.9 14.2-21.9 26.5 0 8.7 3.5 16.1 13.9 16.1 7.9 0 13.8-4.6 16.4-11.5h9.3c-3.4 12.6-12.6 19.7-25.7 19.7-15.4 0-23.2-9-23.2-24.3 0-17.9 11-34.6 31.1-34.6 12.7 0 20.7 6 21.1 19.2h-9.1z"
          />
          <path
            class="st0"
            d="m522.8 42.2h9.3l-6.6 29.7 0.2 0.2c4.7-6.4 12.2-9.8 20-9.8 10.6 0 15.7 4.5 15.7 14.5 0 2.5-0.7 6.6-1.6 11l-6.9 32.1h-9.3l7.2-32.9c0.3-2.2 1.3-6.7 1.3-8.7 0-5.3-4.2-7.8-9.8-7.8-5.1 0-10.5 2.7-13.9 6.5-3.4 2.9-5 7.3-6.2 11.8l-6.5 31.1h-9.3l16.4-77.7z"
          />
          <path
            class="st0"
            d="m588.8 63.6h11.2l-1.7 8.2h-11.3l-7 33.1c-0.1 1.3-0.3 2.4-0.3 3.7 0 2.9 2.6 3.8 5.6 3.8 1.9 0 3.6-0.4 5.3-1l-1.6 8.5c-2.2 0.4-4.4 0.7-6.5 0.7-6.9 0-12.6-2-12.6-9.1 0-1.6 0.2-2.9 0.5-4.7l7.4-34.9h-9.5l1.7-8.2h9.5l3.5-16.7h9.3l-3.5 16.6z"
          />
          <path
            class="st0"
            d="m220.1 42.2h-30.3c-0.5 15.1-1.4 30.5-3.2 39.4-2.1 11.1-6.6 25.3-12.9 38.3h9.9l6.8-31.8h23.1c23 0 29.7-14.8 29.7-24.5 0-14.2-8.2-21.4-23.1-21.4zm-4.5 37.2h-23.4l6-28.5h21.9c6.9 0 12.7 3.2 12.7 12.7 0 9.3-6 15.8-17.2 15.8z"
          />
          <path
            class="st0"
            d="m363.9 136.2c0-2.7-1.6-4.2-4.4-4.2-4.6 0-6.9 4.6-6.9 9 0 3.6 1.9 5.8 5.3 5.8 3.5 0 4.7-3.1 5-4.5h3.4c-1.1 4.9-4.5 7.2-8.6 7.2-5.6 0-8.6-3.7-8.6-8.5 0-8.2 5.3-11.9 10.4-11.9 4.6 0 7.8 2.5 7.7 6.9h-3.3z"
          />
          <path
            class="st0"
            d="m390 129.7h3.4l-1.6 7.7h9.1l1.6-7.7h3.4l-4 19.5h-3.4l1.8-8.8h-9.1l-1.8 8.8h-3.4l4-19.5z"
          />
          <path
            class="st0"
            d="m433.8 129.7h3.6l3.4 19.5h-3.5l-0.8-5.2h-7.7l-3 5.2h-3.7l11.7-19.5zm1.2 3.4-4.7 8.3h5.9l-1.2-8.3z"
          />
          <path
            class="st0"
            d="m464.6 129.7h8.4c1.7 0 6.1 0.2 6.1 5 0 3.1-1.7 4.8-4.4 5.3v0.1c1.6 0.6 2.1 1.9 2.1 3.5 0 1.3-0.3 2.7-0.3 4 0 0.5 0.1 1.1 0.4 1.6h-3.6c-0.2-0.5-0.2-1-0.2-1.5 0-1.4 0.3-2.8 0.3-4.3 0-2.3-1.8-2.3-3.7-2.3h-4.1l-1.7 8h-3.5l4.2-19.4zm1.6 8.9h5c2.9 0 4.5-1.3 4.5-3.5 0-2.1-1.7-2.5-3.5-2.5h-4.8l-1.2 6z"
          />
          <path
            class="st0"
            d="m499.9 129.7h16l-0.6 2.9h-6.2l-3.4 16.5h-3.4l3.4-16.5h-6.4l0.6-2.9z"
          />
          <path
            class="st0"
            d="m536 129.7h14.2l-0.6 2.9h-10.8l-1.1 5.1h10l-0.6 2.8h-10.1l-1.2 5.7h10.9l-0.6 2.9h-14.1l4-19.4z"
          />
          <path
            class="st0"
            d="m571.2 129.7h8.4c1.7 0 6.1 0.2 6.1 5 0 3.1-1.7 4.8-4.4 5.3v0.1c1.6 0.6 2.1 1.9 2.1 3.5 0 1.3-0.3 2.7-0.3 4 0 0.5 0.1 1.1 0.4 1.6h-3.6c-0.2-0.5-0.2-1-0.2-1.5 0-1.4 0.3-2.8 0.3-4.3 0-2.3-1.8-2.3-3.7-2.3h-4.1l-1.7 8h-3.5l4.2-19.4zm1.7 8.9h5c2.9 0 4.5-1.3 4.5-3.5 0-2.1-1.7-2.5-3.5-2.5h-4.8l-1.2 6z"
          />
          <path
            class="st1"
            d="M184,0c0,0-74.5,107-101.6,145.7c20.8-5.4,42.5-4.2,61.6,3.7c19.1-12.5,32.1-46.2,36.2-67.9    C184.4,60,184,0,184,0z M0,148.7c13.7,1.7,64.5-1.2,75.8-1.7c5.4-8.3,55.4-82.4,55.4-82.4C89.5,110.8,41.2,142,0,148.7z"
          />
        </svg>
        <p class="footer-address">
          ProYacht Balaton<br />
          8230 Balatonfüred, Zákonyi Ferenc u. 4.
        </p>
        <p class="footer-address">
          GPS: <a href="https://goo.gl/maps/W5ZUmXqATZDUA41d6">LAT 46.9534388 LON 17.8895443</a><br />
          Telefon: <a href="tel:+36 30 427 2006">+36 30 427 2006</a><br />
          E-mail: <a href="mailto:info@pro-yacht.hu">info@pro-yacht.hu</a>
        </p>
      </div>
    </v-col>
    <v-col cols="12" md="4" align-self="start">
      <p class="footer-title">ProYacht Balaton hasznos</p>
      <p v-for="item in menuItems" v-bind:key="item.title" class="mb-1">
        <a :href="item.slug ? item.link : item.external_url" :target="item.external_url ? '_blank' : ''" class="link link-hover">{{ item.title }}</a>
      </p>
    </v-col>
    <v-col cols="12" md="2" align-self="start">
      <div>
        <span class="footer-title">ProYacht Balaton közösség</span>
        <div class="grid grid-flow-col gap-4 mt-2">
          <a
            class="mr-4"
            href="https://www.facebook.com/proyachthu"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="fill-base-content"
          ><path
            d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
          /></svg
          ></a
          >
          <a
            href="https://instagram.com/proyachtbalaton"
          ><svg
            width="24"
            height="24"
            fill="#000000"
            version="1.1"
            viewBox="0 0 169.06 169.06"
            xml:space="preserve"
            class="fill-base-content"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752   c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407   c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752   c17.455,0,31.656,14.201,31.656,31.655V122.407z"
          /><path
            d="m84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561z"
          /><path
            d="m129.92 28.251c-2.89 0-5.729 1.17-7.77 3.22-2.051 2.04-3.23 4.88-3.23 7.78 0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z"
          /></svg
          ></a
          >
        </div>
      </div>
    </v-col>
  </v-footer>
</template>

<script>
import { getFooterItems } from '../modules/reservationsModule';

export default {
  async created() {
    this.menuItems = (await getFooterItems()).map((x) => ({
      ...x,
      link: `${process.env.VUE_APP_MAIN_SITE_URL}/${x.slug}`,
    }));
  },
  data: () => ({
    menuItems: [],
  }),

};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: white !important;
}

.footer-colored {
  background-color: $footer;
  color: white;
}

.footer-address {
  font-size: 0.875rem;
}
.fill-base-content {
  max-width: 260px;
  fill: #FFF;
}
.footer-title {
  font-size: 0.875rem;
  margin-bottom: .5rem;
  font-weight: 700;
  text-transform: uppercase;
  opacity: .5;
}
.link-hover {
  font-size: 0.875rem;
  text-decoration-line: none;
  color: white;
}

.link-hover:hover {
  text-decoration-line: underline;
}

</style>
