import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import hu from 'vuetify/lib/locale/hu';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { hu },
    current: 'hu',
  },
  theme: {
    themes: {
      light: {
        primary: '#1ba8cd',
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
      },
    },
  },
});
