import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;

const getPackages = async () => {
  const resp = await axios.get('/packages', {
    params: {},
    baseURL,
  });
  return resp.data;
};

const getShips = async () => {
  const resp = await axios.get('/ships', {
    params: {},
    baseURL,
  });
  return resp.data;
};

const getMenuItems = async () => {
  const resp = await axios.get('/menuitems', {
    params: {},
    baseURL,
  });
  return resp.data;
};

const getConfig = async () => {
  const resp = await axios.get('/config', {
    params: {},
    baseURL,
  });
  return resp.data;
};

const getFooterItems = async () => {
  const resp = await axios.get('/footeritems', {
    params: {},
    baseURL,
  });
  return resp.data;
};

const getShipDetails = async (ship) => {
  const resp = await axios.get('/shipDetails', {
    params: { ship },
    baseURL,
  });
  return resp.data;
};

const createReservation = async (data) => {
  const resp = await axios.post('/createReservation', data, {
    baseURL,
  });
  return resp.data;
};

// eslint-disable-next-line vue/max-len
const getPriceCalculation = async (ship, fromDate, toDate, captain, blister, competition, captainDays) => {
  const resp = await axios.get('/calculatePrice', {
    params: {
      ship, fromDate, toDate, captain, blister, competition, captainDays,
    },
    baseURL,
  });
  return resp.data;
};

const getPackageDates = async (data, ship) => {
  const resp = await axios.get('/packageDates', {
    params: { period: data.split(';')[0], package: data.split(';')[1], ship },
    baseURL,
  });
  return resp.data;
};

export {
  getPackages,
  getShips,
  getMenuItems,
  getFooterItems,
  getShipDetails,
  getPackageDates,
  getPriceCalculation,
  createReservation,
  getConfig,
};
