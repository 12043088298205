<template>
  <div class="home" />
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
};
</script>
