import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;

const formatter = new Intl.NumberFormat('hu-HU', {
  style: 'currency',
  currency: 'HUF',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

Vue.filter('priceFormat', (value) => formatter.format(value));

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
