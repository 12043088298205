<template>
  <v-app-bar class="navbar-pr" app dark>
    <div class="d-flex align-center">
      <v-menu class="mobile-menu" offset-y v-if="$vuetify.breakpoint.smAndDown">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="googleTranslateElementInit">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :href="item.link"
            v-for="item in menuItems"
            v-bind:key="item.slug">
            <v-list-item-title class="item-title">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn width="14rem" x-large :href="homeUrl" text>
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          height="3em"
          width="14rem"
          :src="require('../assets/logo.svg')"
          transition="scale-transition"
        />
      </v-btn>

    </div>

    <v-spacer />
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn class="main-menu-link" text v-for="item in menuItems" :href="item.link" v-bind:key="item.slug">{{ item.title }}</v-btn>
    </div>
    <div id="google_translate_element" />

  </v-app-bar>
</template>

<script>
import { getMenuItems } from '../modules/reservationsModule';

export default {
  async created() {
    this.menuItems = (await getMenuItems()).map((x) => ({
      ...x,
      link: `${process.env.VUE_APP_MAIN_SITE_URL}/${x.slug}`,
    }));
    this.googleTranslateElementInit();
  },
  data: () => ({
    homeUrl: process.env.VUE_APP_MAIN_SITE_URL,
    menuItems: [],
  }),
  methods: {
    googleTranslateElementInit() {
      window.google.translate.TranslateElement(
        { pageLanguage: 'hu' },
        'google_translate_element',
      );
    },

  },
};
</script>

<style lang="scss" scoped>
.navbar-pr {
  background-color: $primary-80 !important;
  backdrop-filter: blur(8px);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px !important;
}
.main-menu-link {
  text-transform: none !important;
  font-weight: normal;
  font-size: 1rem !important;
  letter-spacing: unset !important;
}
.v-menu__content {
  border-radius: 1rem !important;
}
.item-title {
  color: $main-color;
  font-size: 14px;
}
.v-list-item {
  min-height: 36px;
}
</style>
<style>
  .goog-te-combo {
    padding: 6px;
    border-radius: 4px;
    color: black !important;
    background-color: white !important;
  }
  .goog-te-gadget {
    color: white !important;
  }
  .goog-te-gadget span{
    display: none;
  }

  .goog-te-gadget{
    font-size: 0 !important;
  }
  .translator-mobile {
    margin-left: 8px;
  }
</style>
